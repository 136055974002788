import React, { useContext, useEffect } from "react";
import skillImg1 from "assets/images/skills/intelligence.png";
import skillImg2 from "assets/images/skills/dexterity.png";
import skillImg3 from "assets/images/skills/speed.png";
import skillImg4 from "assets/images/skills/endurance.png";
import skillImg5 from "assets/images/skills/force.png";
import coinImg from "assets/images/coin.png";
import formatNumber from "utils/format-number";
import appContext from "utils/app-context";
import ProductDrawer from "./ProductDrawer";
import { Product } from "types/product";
import Icon from "UI/Icon";

type Props = {
  list?: Product[];
};

const StoreList = ({ list }: Props) => {
  const { setDrawer } = useContext(appContext);
  useEffect(() => {
    //
  }, []);
  return (
    <ul className="store-list">
      {list?.map((p, index) => {
        const { title, image, price, price_stars, price_ton } = p;
        return (
          <li
            key={index}
            className="store-list-item pink-opacity"
            onClick={() => setDrawer(<ProductDrawer p={p}/>)}
          >
            <p className="store-list-item-title">{title}</p>
            <img
              className="store-list-item--image"
              src={`products/${image}.png`}
              alt=""
            />
            {!!price && (
              <div className="store-list-item--price text-glow">
                <img src={coinImg} alt="" />
                {formatNumber(price)}
              </div>
            )}
            {!!price_stars && (
              <div className="store-list-item--price text-glow">
                <Icon icon="star" />
                {formatNumber(price_stars)}
              </div>
            )}
            {!!price_ton && (
              <div className="store-list-item--price text-glow">
                <Icon icon="ton" />
                {price_ton}
              </div>
            )}
          </li>
        )
      })}
    </ul>
  );
};
const skills = [
  { name: "Intelligence", image: skillImg1, price: 2000 },
  { name: "Dexterity", image: skillImg2, price: 2000 },
  { name: "Speed", image: skillImg3, price: 2000 },
  { name: "Endurance", image: skillImg4, price: 2000 },
  { name: "Force", image: skillImg5, price: 2000 },
];

export default StoreList;
