import { createContext, ReactNode, useEffect, useRef, useState } from "react"
import AppContextType from "types/app-context";
import getUserData from "api/get-user-data";
import UserData from "types/user-data";
import AppContext from "utils/app-context";
import tg from "utils/tg";
import useProducts from "./use-products";
const errors = {
    "appError":"It seems that some kind of error has occurred, you need to go back to the bot and send /start",
    "quit":"Back to the bot"
}
const inviteLink = process.env.REACT_APP_SHARE_LINK?.replace('TG_ID',String(1)) || ""
export default () => {
    const [user, setUser] = useState<UserData | null>(null)
  const [drawer, setDrawer] = useState<null | ReactNode>(null)
    const [showGameRules, setshowGameRules] = useState(false)
    const interval = useRef<NodeJS.Timer | null>(null)
    const ProductsService = useProducts()

    const updateUserInfo = async () => {
        const result = await getUserData()
        if (result) setUser(result)
        if(result?.error) {
            tg.showPopup({message:errors.appError,buttons:[{type:'close',text:errors.quit}]},() => {
                tg.close()
                tg.openTelegramLink(inviteLink)
            })
        }
    }


    const AppContextValue:AppContextType = {
        user,
        updateUserInfo,
        setUser,
        showGameRules,
        setshowGameRules,
        drawer, setDrawer,
        ProductsService
    }


    useEffect(() => {
        updateUserInfo()
        // updateProducts()
    }, [])
    
    return {AppContext,AppContextValue}
}