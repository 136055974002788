import { useTonConnectUI } from "@tonconnect/ui-react";
import createTransaction from "api/create-transaction";
import productBuy from "api/product-buy";
import { useContext, useEffect, useRef, useState } from "react";
import { Product } from "types/product";
import ProductCurrency from "types/product-currency";
import appContext from "utils/app-context";
// import { SendTransactionRequest, TonConnectUI, useTonConnectUI } from "@tonconnect/ui-react";
import tg from "utils/tg";

export default (product?:Product,initialCurrency?:ProductCurrency) => {
  // const [TonConnectUI] = useTonConnectUI()
  const [waitingForConnect, setwaitingForConnect] = useState(false)
  const [loading, setloading] = useState(false)
  const [success, setsuccess] = useState(false)
  const [currency, setcurrency] = useState<ProductCurrency | null>(initialCurrency || null);
  const [tonConnectUI, setOptions] = useTonConnectUI();
const {ProductsService:{fetchProducts}} = useContext(appContext)
  let unsubscribe = useRef<() => void>(null).current

  const handleBuyProduct = async (onSuccess?:() => void,params?:{withoutTonPurchase?:boolean,customCurrency?:ProductCurrency}) => {
    let currentCurrency = params?.customCurrency || currency
    if(!currentCurrency || !product || loading) return
    setloading(true)
    try {
      if (currentCurrency === "price_ton" && !params?.withoutTonPurchase) {
        if (!tonConnectUI.connected) {
          tonConnectUI.openModal();
          setwaitingForConnect(true)
          setloading(false)
          return
        } else {
          const result = await createTransaction(product.id)
          console.log("🚀 ~ handleBuyProduct ~ result:", result)
          if(!result) throw new Error('Error during the creation of transaction')
          const transaction = {
            messages: [
                {
                    address: result.address,
                    amount: String(result.amount * 1000000000),
                }
            ],
              validUntil:Math.floor(Date.now() / 1000) + result.seconds,
            }
            console.log("🚀 ~ handleTonTransaction ~ transaction:", transaction)
            const TransactionRes = await tonConnectUI.sendTransaction(transaction)
            console.log("🚀 ~ handleBuyProduct ~ TransactionRes:", TransactionRes)
        }
      }
      const result = await productBuy(product.id,currentCurrency)
      console.log("🚀 ~ handleBuyProduct ~ result:", result)
      if(result?.result) {
        setsuccess(true)
        tg.HapticFeedback.notificationOccurred('success')
        if(onSuccess) onSuccess()
          await fetchProducts()
      } else throw new Error('Something went wrong')
    } catch (error) {
      console.log("🚀 ~ handleBuyProduct ~ error:", error)
      tg.showAlert(`Something went wrong:\n${error}`)
    }
    setloading(false)
  };
 
  useEffect(() => {
    const checkFromBuyProduct = () => {
      if(tonConnectUI.connected && waitingForConnect) {
        setwaitingForConnect(false)
        handleBuyProduct()
      }
    }

    if(unsubscribe) unsubscribe()
    unsubscribe = tonConnectUI.onStatusChange(checkFromBuyProduct)
    return () => {
      if(unsubscribe) unsubscribe()
    }
  }, [waitingForConnect])
  // useEffect(() => {
  //   if(product && user && currency === null) setcurrency(getInitialCurrency(product,user))
  // }, [user])

  return {loading,success,handleBuyProduct,currency, setcurrency}
}