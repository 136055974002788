import { AnimatePresence, motion, useDragControls } from "framer-motion";
import React, { useContext } from "react";
import appContext from "utils/app-context";
import { bgVariants, bodyVariants } from "utils/drawer-animation";

const BottomDrawer = () => {
  const { drawer, setDrawer } = useContext(appContext);
  const controls = useDragControls()
  // Порог скорости для закрытия
  const velocityThreshold = 100;

  const handlePointerDown = (e:React.PointerEvent) => {
    // const t = e.target as HTMLElement
    // if (['button', 'input', 'textarea', 'select'].includes(t.localName)) {
    //   return;
    // }
    controls.start(e)
  }

  return (
    <AnimatePresence>
      {drawer && (
        <motion.div
          {...bgVariants}
          className="drawer-bg"
          onClick={() => setDrawer(null)}
        >
          <motion.div
            {...bodyVariants}
            drag="y"
            // dragListener={false}
            dragControls={controls}
            dragConstraints={{ top: 0, bottom: 0 }}
            onPointerDown={handlePointerDown}
            onDragEnd={(_, info) => {
              if (info.velocity.y > velocityThreshold) {
                setDrawer(null);
              }
            }}
            onClick={(e) => e.stopPropagation()}
            className="drawer-body"
          >
            {drawer}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default BottomDrawer;

