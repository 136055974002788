import { HTMLMotionProps } from "framer-motion";

export const bodyVariants: HTMLMotionProps<"div"> = {
  initial: {
    y: '100%',
  },
  animate: {
    y: '0%',
  },
  exit: {
    y: '100%',
    pointerEvents:"none",
    transition:{
      duration:0.2,
      ease:'circInOut'
    }
  },
  transition:{
    duration:0.3,
    ease:'circInOut'
  }
};

export const opacityVariants: HTMLMotionProps<"div"> = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  };
  

export const bgVariants: HTMLMotionProps<"div"> = {
  initial: {
    background: "transparent",
    backdropFilter: "blur(0px)",
  },
  animate: {
    background: "#0000008f",
    backdropFilter: "blur(10px)",
  },
  exit: {
    background: "transparent",
    backdropFilter: "blur(0px)",
    transition: {
      duration: 0.15,
    }
  },
  transition: {
    // duration:0.4
  },
};
