import React, { useContext, useEffect, useState } from 'react'
import './Wallet.css'
import PageWrapper from 'UI/PageWrapper'
import Icon from 'UI/Icon'
import Button from 'UI/Button'
import coinImg from 'assets/images/coin.png'

import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion'
import formatNumber from 'utils/format-number'
import appContext from 'utils/app-context'
import Transaction from 'types/transaction'
import getTransactions from 'api/get-transactions'
import formatDate from 'utils/format-date'
import WalletTonConnect from './WalletTonConnect'
import { TonConnectUIProvider } from '@tonconnect/ui-react'
import tg from 'utils/tg'

const Wallet = () => {
  const {user} = useContext(appContext)
  const [category, setCategory] = useState(false);
  const [transactions, settransactions] = useState<Transaction[] | null>(null)
  
  const soon = () => tg.showAlert('Coming soon...')

  useEffect(() => {
    getTransactions().then(res => {
      if(res) settransactions(res)
    })
  }, [])
  return (
    
    <PageWrapper className='blured-page'>
      <h1>wallet</h1>
      <WalletTonConnect/>
      <div className="task-category-select">
        <Button
          className={!category ? "active" : "pink-opacity"}
          onClick={() => setCategory(false)}
        >
          Balance
        </Button>
        <Button
          className={category ? "active" : "pink-opacity"}
          onClick={() => setCategory(true)}
        >
          History
        </Button>
      </div>
      <AnimatePresence initial={false} mode='sync'>
        {!category && <СategoryWrapper direction>
        <ul className='balance-list'>
          <li className='pink-opacity'>
            <div className="balance-info">
              <img src={coinImg} alt="" />
              <h5>{formatNumber(user?.balance)}</h5>
            </div>
            <div className="balance-actions">
              <Button onClick={soon}>
                <Icon icon='receiveCircle'/>
              </Button>
              <Button onClick={soon}>
                <Icon icon='sendCircle'/>
              </Button>
            </div>
          </li>
          <li className='pink-opacity'>
            <div className="balance-info">
            <Icon icon='ruby'/>
              <h5>{formatNumber(user?.diamonds)}</h5>
            </div>
            <div className="balance-actions">
              <Button onClick={soon}>
                <Icon icon='receiveCircle'/>
              </Button>
              <Button onClick={soon}>
                <Icon icon='sendCircle'/>
              </Button>
            </div>
          </li>
          <li className='pink-opacity'>
            <div className="balance-info">
              <Icon icon='star'/>
              <h5>{formatNumber(user?.stars)}</h5>
            </div>
            <div className="balance-actions">
              <Button onClick={soon}>
                <Icon icon='receiveCircle'/>
              </Button>
              <Button onClick={soon}>
                <Icon icon='sendCircle'/>
              </Button>
            </div>
          </li>
          <li className='pink-opacity'>
            <div className="balance-info">
              <Icon icon='ton'/>
              <h5>{user?.ton}</h5>
            </div>
            <div className="balance-actions">
              <Button onClick={soon}>
                <Icon icon='receiveCircle'/>
              </Button>
              <Button onClick={soon}>
                <Icon icon='sendCircle'/>
              </Button>
            </div>
          </li>
        </ul>
        </СategoryWrapper>}
        {category && <СategoryWrapper>
          <ul className='balance-list'>
            {transactions?.map(({amount,coin,created_at,comment}) => {
              let icon = <img src={coinImg} width={24} height={24}/>

              switch (coin) {
                case 'diamonds':
                  icon = <Icon icon='ruby'/>
                  break;
              
                case 'stars':
                  icon = <Icon icon='star'/>
                  break;

                case 'ton':
                  icon = <Icon icon='ton'/>
                  break;
              }
              return (
                <li className='pink-opacity transaction'>
                <div className="balance-info">
                  <span>{formatDate(created_at)}</span>
                  <p>{comment}</p>
                </div>
                <div className="balance-actions">
                 {icon}
                 <h5>{amount > 0 ? '+' : ''}{amount}</h5>
                </div>
              </li>
              )
            })}
          </ul>
        </СategoryWrapper>}
      </AnimatePresence>
    </PageWrapper>
  )
}
interface СategoryWrapperProps extends HTMLMotionProps<"div"> {
  direction?: boolean
}
const СategoryWrapper = (props:СategoryWrapperProps,direction:boolean) => {
  return <motion.div {...props}
  initial={{x:direction ? '120%' : '-120%'}}
  animate={{x:'0%'}}
  exit={{x:!direction ? '120%' : '-120%'}}
  >
    {props.children}
  </motion.div>
}
export default Wallet