import { AnimatePresence, motion, Variants } from "framer-motion";
import { ReactNode, useRef, useState } from "react";

type Tab = {
  title: ReactNode;
  elem: ReactNode;
};

type Props = {
  tabs: Tab[];
  bodyClassName?:string

};

const Tabs = ({ tabs,bodyClassName }: Props) => {
  const [[index, dir], setIndex] = useState([0, 0]);
  const prev = useRef(0);

  const Move = (newIndex: number) => {
    prev.current = index
    setIndex([newIndex,index > newIndex ? -1 : 1])
  };

  return (
    <div className="tabs">
      <div className="tab-buttons glass">
        {tabs.map(({ title }, i) => {
          return (
            <button
            key={i}
              className={`title-font pink-opacity ${i === index ? "current" : ""}`}
              onClick={() => Move(i)}
            >
              {title}
            </button>
          );
        })}
       
      </div>
      <div className="tabs-body">
        <AnimatePresence initial={false} custom={dir}>
          <motion.div
            custom={dir}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            key={index}
            transition={{
                x: { type: "spring", stiffness: 400, damping: 30 },
                opacity: { duration: 0.2 }
              }}
            className={bodyClassName}
          >
           {tabs[index].elem}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

const variants: Variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? '120%' : '-120%',
      opacity: 0,
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? '120%' : '-120%',
      opacity: 0,
      height:'100%',
      position: "absolute",
      top:0
    };
  },
};

export default Tabs;
