import React, { useContext, useEffect } from "react";
import img from "assets/images/skills/intelligence.png";
import img2 from "assets/images/skills/force.png";
import Button from "UI/Button";
import coinImg from "assets/images/coin.png";
import { Product } from "types/product";
import formatNumber from "utils/format-number";
import Icon from "UI/Icon";
import usePurchase from "hooks/use-purchase";
import { toast } from "react-toastify";
import appContext from "utils/app-context";

type Props = {
  p: Product;
};

const ProductDrawer = ({ p }: Props) => {
  const { image, price, price_stars, price_ton, title } = p;
  const { handleBuyProduct, loading, setcurrency,currency,success } = usePurchase(p);
  const {setDrawer} = useContext(appContext);
  
  const onSuccess = () => {
    toast.success('You successfully increased skill',{
      icon:<img
      src={`products/${image}.png`}
      alt=""
      className="product-drawer-toast-image"
    />
    })
    setDrawer(null)
}

  return (
    <div className="product-drawer">
      <p className="product-drawer-title">{title} +20</p>
      <img
        src={`products/${image}.png`}
        alt=""
        className="product-drawer-image"
      />
      <div className="product-drawer--side-effect">
        <img src={img2} alt="" />
        -3
      </div>
      {!!price && (
        <Button
          onClick={() => {
            handleBuyProduct(onSuccess,{customCurrency:'price'});
          }}
          loading={currency === "price" && loading}
          className="product-drawer-btn"
        >
          <img src={coinImg} alt="" />
          {formatNumber(price)}
        </Button>
      )}
      {!!price_stars && (
        <Button
          onClick={() => {
            handleBuyProduct(onSuccess,{customCurrency:'price_stars'});
          }}
          loading={currency === "price_stars" && loading}

          className="product-drawer-btn"
        >
          <Icon icon="star" />
          {formatNumber(price_stars)}
        </Button>
      )}
      {!!price_ton && (
        <Button
          onClick={() => {
            handleBuyProduct(onSuccess,{customCurrency:'price_ton'});
          }}
          loading={currency === "price_ton" && loading}
          className="product-drawer-btn"
        >
          <Icon icon="ton" />
          {price_ton}
        </Button>
      )}
    </div>
  );
};

export default ProductDrawer;
