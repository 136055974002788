import getProducts from "api/get-products"
import { useEffect, useMemo, useState } from "react"
import { Product } from "types/product"
import tg from "utils/tg"

export default () => {
    const [products, setproducts] = useState<null | Product[]>(null)

    const fetchProducts = async () => {
       const result = await getProducts()
       if(result) {
              setproducts(result)
       } else tg.showAlert('Error fetching products')
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    return {
        products,
        fetchProducts,
    }
}