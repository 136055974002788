import React, { useContext, useRef, useState } from "react";
import PageWrapper from "UI/PageWrapper";
import formatNumber from "utils/format-number";
import coinImg from "assets/images/coin.png";
import temp from "assets/images/ubder-construction.png";
import "./Main.css";
import appContext from "utils/app-context";
import { AnimatePresence, motion } from "framer-motion";
import skillImg1 from "assets/images/skills/intelligence.png";
import skillImg2 from "assets/images/skills/dexterity.png";
import skillImg3 from "assets/images/skills/speed.png";
import skillImg4 from "assets/images/skills/endurance.png";
import skillImg5 from "assets/images/skills/force.png";
import navBarIcon from "assets/images/nav-images/store.png";
import character from 'assets/images/hero.png'
import Button from "UI/Button";
import { useNavigate } from "react-router-dom";
type Props = {};

const Main = (props: Props) => {
  const { user } = useContext(appContext);
  const [showLevelsInfo, setShowLevelsInfo] = useState(false)
  // const [greeting, setgreeting] = useState<boolean>(false)
  // const timer = useRef<NodeJS.Timeout | null>(null)
  // const showGreeting = () => {
  //   if (greeting) {
  //     if(timer.current) clearTimeout(timer.current)
  //   } else timer.current = setTimeout(() => setgreeting(false), 6000);

  //   setgreeting(!greeting)
  // }
  const nav = useNavigate()
  return (
    <PageWrapper
      className="main-page"
      // onClick={showGreeting}
    >
      <div className="main-top-bar">
        <div className="main-top-bar-profile">
          <img src={user?.avatar} alt="" />
          <p>{user?.first_name}</p>
        </div>
        <div className="main-top-bar-activity">
          <p>{user?.days_in_game}</p>
          <span>days in game</span>
        </div>
        <div className="main-top-bar-balance text-glow">
          <img src={coinImg} alt="" />
          {formatNumber(user?.balance)}
        </div>
      </div>
      <div className="character-stats">
        <div className="character-stats--main">
          <p className="character-lvl text-glow">{user?.level} level</p>
          <p className="character-income">Income/hour</p>
          <div className="character-income-value">
            <Button className="character-income-info" onClick={() => setShowLevelsInfo(prev => !prev)}/>
            <img src={coinImg} alt="" />
            <p className="text-glow">{user?.profit_hour}</p>
          </div>
          <button className={`nav-button pink-opacity`} onClick={() => nav('/store')}>
            <img src={navBarIcon} alt="" />
            <p>Store</p>
          </button>
        </div>
        <ul className="character-stats--skills">
          <li>
            <img src={skillImg1} alt="" />
            <p className="text-glow">{user?.metrics.iq}</p>
          </li>
          <li>
            <img src={skillImg2} alt="" />
            <p className="text-glow">{user?.metrics.dexterity}</p>
          </li>
          <li>
            <img src={skillImg3} alt="" />
            <p className="text-glow">{user?.metrics.speed}</p>
          </li>
          <li>
            <img src={skillImg4} alt="" />
            <p className="text-glow">{user?.metrics.endurance}</p>
          </li>
          <li>
            <img src={skillImg5} alt="" />
            <p className="text-glow">{user?.metrics.strength}</p>
          </li>
        </ul>
      </div>
      <img src={character} alt="" className="main-page-character-image"/>
      <AnimatePresence>
        {showLevelsInfo && <motion.div
          initial={{opacity:0,y:100}}
          animate={{opacity:1,y:0}}
          exit={{opacity:0,y:100}}
          className="main-page-levels-info pink-opacity"
        >
          <Button className="main-page-levels-info--close" onClick={() => setShowLevelsInfo(false)}/>
          <p className="character-lvl text-glow">2 level</p>
          <p className="character-income">Income/hour</p>
          <ul>
            {user?.levels.map((value, index) => (
              <li key={index}>
                <p>{index +1} lvl</p>
                <div className="character-income-value">
                  <img src={coinImg} alt="" />
                  <p className="text-glow">{value}</p>
                </div>
              </li>
            ))}
          </ul>
        </motion.div>}
      </AnimatePresence>
      {/* <AnimatePresence>
      {greeting && <motion.div
            initial={{opacity:0,rotate:-20,scale:0}}
            animate={{opacity:1,rotate:0,scale:1}}
            exit={{opacity:0,rotate:20,scale:0.5}}
            className="hero-greeting">
              Hi {user?.first_name}! <br />
              The main character will be available coming soon
            </motion.div>}
      </AnimatePresence> */}
      {/* <img src={temp} className='under-construction' /> */}
    </PageWrapper>
  );
};

export default Main;
