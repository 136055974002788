import React, { useContext, useMemo } from 'react'
import PageWrapper from 'UI/PageWrapper'
import './Store.css'
import Tabs from 'UI/Tabs'
import StoreList from './components/StoreList'
import appContext from 'utils/app-context'
import { Product } from 'types/product'
type Props = {}

const Store = (props: Props) => {
  const {ProductsService:{products}} = useContext(appContext)
  
  const categorized = useMemo(() => {
    const initial:Record<string,Product[]> = {
      standart: [],
      premium: [],
      purchases: [],
    }
    return products?.reduce((acc, product) => {
      if (product.category === 0) {
        acc.standart.push(product)
      } else if (product.category === 1) {
        acc.premium.push(product)
      } else {
        acc.purchases.push(product)
      }
      return acc
    }
    , initial)
  },[ products ])
  
  return (
    <PageWrapper className='store-page blured-page'>
      <h1>store</h1>
      <Tabs
      tabs={[
        {
          title:'standart',
          elem:<StoreList list={categorized?.standart}/>
        },
        {
          title:'premium',
          elem:<StoreList list={categorized?.premium}/>
        },
        {
          title:'Purchases',
          elem:<StoreList list={categorized?.purchases}/>
        },
      ]}
      />
    </PageWrapper>
  )
}

export default Store